import {addRefreshAuthToAuthProvider, addRefreshAuthToDataProvider, fetchUtils} from 'react-admin';
import { stringify } from 'query-string';
import { Environment } from "./configProvider";
import refreshAuth from "./refreshAuth";

const cloudRegion = localStorage.getItem('cloud-region');
const cloudProvider = localStorage.getItem('cloud-provider')
    ? localStorage.getItem('cloud-provider')
    : (Environment.MultiRegion === true ? Object.keys(Environment.API.Platform)[0] : "Azure");

const kuggarApiUrl = Environment.API.Kuggar;

const pfApiUrl = Environment.MultiRegion === true 
    ? (Environment.API.Platform[cloudProvider][cloudRegion]
        ? Environment.API.Platform[cloudProvider][cloudRegion]
        : Environment.API.Platform[Object.keys(Environment.API.Platform)[0]][Object.keys(Environment.API.Platform[Object.keys(Environment.API.Platform)[0]])[0]])
    : Environment.API.Platform;

const vcApiUrl = Environment.MultiRegion === true 
    ? (Environment.API.VirtualCards[cloudProvider][cloudRegion]
        ? Environment.API.VirtualCards[cloudProvider][cloudRegion]
        : Environment.API.VirtualCards[Object.keys(Environment.API.VirtualCards)[0]][Object.keys(Environment.API.VirtualCards[Object.keys(Environment.API.VirtualCards)[0]])[0]])
    : Environment.API.VirtualCards;

const ipApiUrl = Environment.MultiRegion === true
    ? (Environment.API.IssuerProcessor[cloudProvider][cloudRegion]
        ? Environment.API.IssuerProcessor[cloudProvider][cloudRegion]
        : Environment.API.IssuerProcessor[Object.keys(Environment.API.IssuerProcessor)[0]][Object.keys(Environment.API.IssuerProcessor[Object.keys(Environment.API.IssuerProcessor)[0]])[0]])
    : Environment.API.IssuerProcessor;

const aiApiUrl = Environment.MultiRegion === true 
    ? (Environment.API.OpenAI[cloudProvider][cloudRegion]
        ? Environment.API.OpenAI[cloudProvider][cloudRegion]
        : Environment.API.OpenAI[Object.keys(Environment.API.OpenAI)[0]][Object.keys(Environment.API.OpenAI[Object.keys(Environment.API.OpenAI)[0]])[0]])
    : Environment.API.OpenAI;

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('auth');
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
};

const myDataProvider = {
    getList: (resource, params) => {
        const query = {
            filter: params.filter.q,
            page: params.pagination.page,
            pageSize: params.pagination.perPage,
            order: params.sort.order,
            sort: params.sort.field,
            ...params.filter
        };

        let resourceUrl = '';
        if (resource === 'virtual_card') {
            resourceUrl = `${vcApiUrl}/virtual_card/list`;
            query.fetchedFromCache = true;
            if (params.filter.provider)
                query.provider = params.filter.provider;
            if (params.filter.state) {
                if (params.filter.state === 'ExcludeCancelled')
                    query.excludeCancelledCards = true;
                else
                    query.state = params.filter.state;
            }
            if (params.filter.validFrom)
                query.validFrom = params.filter.validFrom;
            if (params.filter.validTo)
                query.validTo = params.filter.validTo;
        }    
        else if (resource === 'cards_near_expiry') {
            resourceUrl = `${vcApiUrl}/virtual_card/list`;
            query.fetchedFromCache = true;
            if (params.filter.provider)
                query.provider = params.filter.provider;
            if (params.filter.state)
                query.state = params.filter.state;
            if (params.filter.daysToExpiry)
                query.daysToExpiry = params.filter.daysToExpiry;
        }
        else if (resource === 'issuer_account' || resource === 'issuer_environment') {
            resourceUrl = `${vcApiUrl}/${resource}/list`;
            if (params.filter.provider)
                query.provider = params.filter.provider;   
        }
        else if (resource === 'issuer_credentials') {
            resourceUrl = `${vcApiUrl}/issuer_credential/list`;
            if (params.filter.provider)
                query.provider = params.filter.provider;
        }
        else if (resource === 'cache') {
            resourceUrl = `${vcApiUrl}/cache/list`;
            if (params.meta && params.meta.currency !== undefined && params.meta.currency !== null) {
                query.currencyCode = params.meta.currency;
            }
        }
        else if (resource === 'balance_instrument') {
            resourceUrl = `${vcApiUrl}/prepaid_account/list`;
            query.active = 'true';
            query.organisationId = params.filter?.organisationId;
            if (params.meta && params.meta.currency !== undefined && params.meta.currency !== null) {
                query.currencyCode = params.meta.currency;
            }
        }
        else if (resource === 'routing')
            resourceUrl = `${vcApiUrl}/cache_route/list`;
        else if (resource === 'codes/currency')
            resourceUrl = `${pfApiUrl}/${resource}/with_country`;
        else if (resource === 'currency')
            resourceUrl = `${pfApiUrl}/codes/currency`;
        else if (resource === 'user_data_definition')
            resourceUrl = `${vcApiUrl}/user_defined_datamap/definitions`;
        else if (resource === 'user_defined_data')
        {
            resourceUrl = `${vcApiUrl}/user_defined_data/list`;
            query.issuerAccountId = params.filter?.issuerAccountId;
        }
        else if (resource === 'user_defined_data_label')
            resourceUrl = `${vcApiUrl}/user_defined_data/labels`;
        else if (resource === 'user_defined_datamap_account_definitions')
            resourceUrl = `${vcApiUrl}/user_defined_datamap/account_definitions`;
        else if (resource === 'ip_access_profile')
            resourceUrl = `${ipApiUrl}/access_profiles/list`;
        else if (resource === 'ip_issuer_management')
            resourceUrl = `${ipApiUrl}/issuers/list`;
        else if (resource === 'ip_scheme')
            resourceUrl = `${ipApiUrl}/schemes/list`;
        else if (resource === 'ip_card_processor_policy')
            resourceUrl = `${ipApiUrl}/card_processor_policies/list`;
        else if (resource === 'ip_card_issuer_template')
            resourceUrl = `${ipApiUrl}/card_issuer_templates/list`;
        else if (resource === 'ip_processor_user')
            resourceUrl = `${ipApiUrl}/processor_users/list`;
        else if (resource === 'ip_bin_range')
            resourceUrl = `${ipApiUrl}/bin_ranges/list`;
        else if (resource === 'ip_account')
            resourceUrl = `${ipApiUrl}/accounts/list`;
        else if (resource === 'ip_card')
            resourceUrl = `${ipApiUrl}/cards/list`;
        else if (resource === 'ip_authorization_inquiries')
            resourceUrl = `${ipApiUrl}/authorization_inquiries/list`;
        else if (resource === 'ip_exceptions')
            resourceUrl = `${ipApiUrl}/exceptions/list`;
        else if(resource === 'ip_currency_conversions')
            resourceUrl = `${ipApiUrl}/currency_conversions/list`;
        else if(resource === 'uatp_service_logs')
            resourceUrl = `${ipApiUrl}/uatp_service_logs/list`;
        else if (resource === 'vc_access_profile')
            resourceUrl = `${vcApiUrl}/access_profile/list`;
        else if (resource === 'encryption_key')
            resourceUrl = `${vcApiUrl}/encryption_key/list`;
        else if (resource === 'credential')
            resourceUrl = `${pfApiUrl}/credential`;
        else if (resource === 'linked_logs')
            resourceUrl = `${pfApiUrl}/logs/linked/${params.filter.linkedEntityName}/${params.filter.linkedEntityId}`;
        else if (resource === 'vc_transactions' || resource === 'vc_authorisations') {
            resourceUrl = `${vcApiUrl}/virtual_card/${resource.substr(3)}`;
            query.virtualCardId = params.filter.virtualCardId;
        }
        else if (resource === 'vc_scheduled_loads') {
            resourceUrl = `${vcApiUrl}/prepaid_account/${resource.substr(3)}`;
            query.virtualCardId = params.filter.virtualCardId;
        }
        else if (resource === 'card_activity') 
            resourceUrl = `${vcApiUrl}/report_data/card_activity/list`;
        else if (resource === 'funding_account_activity')
            resourceUrl = `${vcApiUrl}/report_data/funding_account_activity/list`;
        else if (resource === 'non_zero_card_balance') {
            resourceUrl = `${vcApiUrl}/report_data/non_zero_card_balance/list`;   
            if (query.startDate) {
                var date = new Date(query.startDate);
                date.setDate(date.getDate() + 1);
                query.endDate = date.toISOString().split('T')[0];
            }
            if (query.active === 'true') {
                query.startDate = null;
                query.endDate = null;
            }
        }
        else if (resource === 'issuer_ftp')
        {
            resourceUrl = `${vcApiUrl}/issuer_ftp/list`;
            if (params.filter.provider)
                query.provider = params.filter.provider;
        }
        else if (resource === 'access_profile/list')
            resourceUrl = `${vcApiUrl}/access_profile/list`;
        else if (resource === 'encryption_key/list')
            resourceUrl = `${vcApiUrl}/encryption_key/list`;
        else if (resource === 'issuer_environment/list')
            resourceUrl = `${vcApiUrl}/issuer_environment/list`;
        else if (resource === 'onboarding')
            resourceUrl = `${vcApiUrl}/onboarding/list`;
        else if (resource === 'currency-exchange') {
            resourceUrl = `${pfApiUrl}/currency-exchange/${params.filter.currencyCode ?? '08eeae4b-93f8-49f0-8ec8-5282985899d8'}`;
            return httpClient(resourceUrl).then(({ headers, json }) => {
                const dataWithIds = json.rates.map(item => ({
                    ...item, id: item.currencyId
                }))
                return {
                    data: dataWithIds,
                    total: null,
                }
            });
        }
        else if (resource === 'rules')
            resourceUrl = `${kuggarApiUrl}/virtual_card/cache/routing_rules`;
        else if (resource === 'ip_onboarding')
            return Promise.resolve({ data: [], total: 0 });
        else
            resourceUrl = `${pfApiUrl}/${resource}`;

        let url = `${resourceUrl}?${stringify(query)}`;
        if (resource === 'user_defined_datamap_account_definitions')
            url = `${resourceUrl}?UserDefinedDatamapId=${params.id}&Page=${query.page}&PageSize=${query.pageSize}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.has('content-range') ? headers.get('content-range').split('/').pop() : json.length, 10),
        }));
    },

    getOne: (resource, params) => {
        let url = '';
        if (resource === 'virtual_card')
            url = `${vcApiUrl}/virtual_card?VirtualCardId=${params.id}`;
        else if (resource === 'cache') {
            if (params.meta && params.meta.is_vc_create === true && params.id === undefined) 
                return Promise.resolve({data: { id: params.id }});
            
            url = `${vcApiUrl}/cache?cacheId=${params.id}`;
        }
        else if (resource === 'balance_instrument')
            url = `${vcApiUrl}/prepaid_account?cacheId=${params.id}`;
        else if (resource === 'routing' && params.id !== 'default')
            url = `${vcApiUrl}/cache_route?cacheId=${params.id}&defaultFlag=false`;
        else if (resource === 'routing' && params.id === 'default')
            url = `${vcApiUrl}/cache_route?defaultFlag=true`;
        else if (resource === 'identity')
            url = `${kuggarApiUrl}/${resource}`;
        else if (resource === 'issuer_account')
            url = `${vcApiUrl}/${resource}?issuerAccountId=${params.id}`;
        else if (resource === 'user_defined_data')
            url = `${vcApiUrl}/user_defined_data?userDefinedDataId=${params.id}`;
        else if (resource === 'ip_access_profile')
            url = `${ipApiUrl}/access_profiles/${params.id}`;
        else if (resource === 'ip_issuer_management')
            url = `${ipApiUrl}/issuers/${params.id}`;
        else if (resource === 'ip_scheme')
            url = `${ipApiUrl}/schemes/${params.id}`;
        else if (resource === 'ip_card_processor_policy')
            url = `${ipApiUrl}/card_processor_policies/${params.id}`;
        else if (resource === 'ip_card_issuer_template')
            url = `${ipApiUrl}/card_issuer_templates/${params.id}`;
        else if (resource === 'ip_processor_user')
            url = `${ipApiUrl}/processor_users/${params.id}`;
        else if (resource === 'ip_bin_range')
            url = `${ipApiUrl}/bin_ranges/${params.id}`;
        else if (resource === 'ip_account')
            url = `${ipApiUrl}/accounts/${params.id}`;
        else if (resource === 'ip_card')
            url = `${ipApiUrl}/cards/${params.id}`;
        else if (resource === 'ip_authorization_inquiries')
            url = `${ipApiUrl}/authorization_inquiries/${params.id}`;
        else if (resource === 'ip_exceptions')
            url = `${ipApiUrl}/exceptions/${params.id}`;
        else if(resource === 'ip_currency_conversions')
            url = `${ipApiUrl}/currency_conversions/${params.id}`;
        else if(resource === 'uatp_service_logs')
            url = `${ipApiUrl}/uatp_service_logs/${params.id}`;
        else if (resource === 'vc_access_profile')
            url = `${vcApiUrl}/access_profile?accessProfileId=${params.id}`;
        else if (resource === 'encryption_key')
            url = `${vcApiUrl}/encryption_key?encryptionKeyId=${params.id}`;
        else if (resource === 'issuer_credentials')
            url = `${vcApiUrl}/issuer_credential?issuerCredentialId=${params.id}`;
        else if (resource === 'issuer_environment')
            url = `${vcApiUrl}/issuer_environment?issuerEnvironmentId=${params.id}`;
        else if (resource === 'card_activity' || resource === 'funding_account_activity' || resource === 'non_zero_card_balance')
            url = `${vcApiUrl}/report_data?reportDataId=${params.id}`;
        else if (resource === 'issuer_ftp')
            url = `${vcApiUrl}/issuer_ftp?issuerFtpId=${params.id}`;
        else
            url = `${pfApiUrl}/${resource}/${params.id}`;
        
        return httpClient(url).then(({ json }) => (resource === 'routing'
            ? { data: { ...json, id: params.id } }
            : { data: json }
        ));
    },

    getMany: async (resource, params) => {
        const query = {
            //filter: JSON.stringify({ id: params.ids }),
            page: 1,
            pageSize: 100000
        };
        
        let resourceUrl = '';
        if (resource === 'virtual_card') {
            resourceUrl = `${vcApiUrl}/virtual_card/list`;
            query.fetchedFromCache = true;
        }            
        else if (resource === 'issuer_account' || resource === 'issuer_environment')
            resourceUrl = `${vcApiUrl}/${resource}/list`;
        else if (resource === 'issuer_credentials')
            resourceUrl = `${vcApiUrl}/issuer_credential/list`;
        else if (resource === 'cache' || resource === 'prepaid_account')
            resourceUrl = `${vcApiUrl}/${resource}/list`;
        else if (resource === 'routing')
            resourceUrl = `${vcApiUrl}/cache_route/list`;
        else if (resource === 'codes/currency')
            resourceUrl = `${pfApiUrl}/${resource}/with_country`;
        else if (resource === 'currency')
            resourceUrl = `${pfApiUrl}/codes/currency`;
        else if (resource === 'user_data_definition')
            resourceUrl = `${vcApiUrl}/user_defined_datamap/definitions`;
        else if (resource === 'user_defined_data')
            resourceUrl = `${vcApiUrl}/user_defined_data/list`;
        else if (resource === 'user_defined_data_label')
            resourceUrl = `${vcApiUrl}/user_defined_data/labels`;
        else if (resource === 'user_defined_datamap_account_definitions')
            resourceUrl = `${vcApiUrl}/user_defined_datamap/account_definitions`;
        else if (resource === 'ip_scheme')
            resourceUrl = `${ipApiUrl}/schemes/list`;
        else if (resource === 'ip_issuer_management')
            resourceUrl = `${ipApiUrl}/issuers/list`;
        else if (resource === 'ip_card')
            resourceUrl = `${ipApiUrl}/cards/list`;
        else if (resource === 'ip_card_issuer_template')
            resourceUrl = `${ipApiUrl}/card_issuer_templates/list`;
        else if (resource === 'ip_card_processor_policy')
            resourceUrl = `${ipApiUrl}/card_processor_policies/list`;
        else if (resource === 'ip_bin_range')
            resourceUrl = `${ipApiUrl}/bin_ranges/list`;
        else if (resource === 'ip_account')
            resourceUrl = `${ipApiUrl}/accounts/list`;
        else if (resource === 'ip_authorization_inquiries')
            resourceUrl = `${ipApiUrl}/authorization_inquiries/list`;
        else if (resource === 'vc_access_profile')
            resourceUrl = `${vcApiUrl}/access_profile/list`;
        else if (resource === 'encryption_key')
            resourceUrl = `${vcApiUrl}/encryption_key/list`;
        else if (resource === 'card_activity') {
            resourceUrl = `${vcApiUrl}/report_data/list`;
            query.filenamePrefix = 'Card_Activity';
        }
        else if (resource === 'funding_account_activity') {
            resourceUrl = `${vcApiUrl}/report_data/list`;
            query.filenamePrefix = 'Funding_Account_Activity';
        }
        else if (resource === 'non_zero_card_balance') {
            resourceUrl = `${vcApiUrl}/report_data/list`;
            query.filenamePrefix = 'Non_Zero_Card_Balance';
        }
        else if (resource === 'credential')
            resourceUrl = `${pfApiUrl}/credential`;
        else
            resourceUrl = `${pfApiUrl}/${resource}`;

        let url = `${resourceUrl}?${stringify(query)}`;
        const response = await httpClient(url).then(({ json }) => ({ data: json }));
        if (params && params.ids) {
            return Promise.resolve({ data: response.data.filter(x => params.ids.includes(x.id)) });
        }
        return Promise.resolve(response);
    },

    create: (resource, params) => {
        let url = '';
        if (resource === 'virtual_card') {
            url = `${vcApiUrl}/virtual_card/smart`;
            if (params.data.userDefinedFields) {
                let keys = Object.keys(params.data.userDefinedFields);
                var udfArray = [];
                keys.forEach(function (item, index, array) {
                    if (params.data.userDefinedFields[item]) {
                        let udf = {
                            "label": item,
                            "value": params.data.userDefinedFields[item]
                        };
                        udfArray.push(udf);
                    }
                })
                params.data.userDefinedFields = udfArray;
                params.data.translateUserDefinedFields = true;
            }
            if (params.data.method === 'Basic') {
                params.data.cacheId = null;
            }
            if (params.data.fundingCurrencyCode === undefined) {
                params.data.fundingCurrencyCode = params.data.currencyCode;
            }
            delete params.data.method;
        }            
        else if (resource === 'cache')
            url = `${vcApiUrl}/cache`;
        else if (resource === 'issuer_account')
            url = `${vcApiUrl}/issuer_account`;
        else if (resource === 'user_defined_data')
            url = `${vcApiUrl}/user_defined_data`;
        else if (resource === 'ip_access_profile')
            url = `${ipApiUrl}/access_profiles`;
        else if (resource === 'ip_issuer_management')
            url = `${ipApiUrl}/issuers`;
        else if (resource === 'ip_scheme')
            url = `${ipApiUrl}/schemes`;
        else if (resource === 'ip_card_processor_policy')
            url = `${ipApiUrl}/card_processor_policies`;
        else if (resource === 'ip_card_issuer_template')
            url = `${ipApiUrl}/card_issuer_templates`;
        else if (resource === 'ip_processor_user')
            url = `${ipApiUrl}/processor_users`;
        else if (resource === 'ip_bin_range')
            url = `${ipApiUrl}/bin_ranges`;
        else if (resource === 'ip_account')
            url = `${ipApiUrl}/accounts`;
        else if (resource === 'ip_card')
            url = `${ipApiUrl}/cards`;
        else if (resource === 'vc_access_profile')
            url = `${vcApiUrl}/access_profile`;
        else if (resource === 'encryption_key')
            url = `${vcApiUrl}/encryption_key`;
        else if (resource === 'issuer_credentials')
            url = `${vcApiUrl}/issuer_credential`;
        else if (resource === 'issuer_environment')
            url = `${vcApiUrl}/issuer_environment`;
        else if (resource === 'issuer_ftp')
            url = `${vcApiUrl}/issuer_ftp`;
        else if (resource === 'notification') {
            url = `${pfApiUrl}/notification`;

            params.data.recipients = [];
            params.data.recipientIds.forEach(recipient => {
                params.data.recipients.push(recipient);
            });
            params.data.recipientIds = null;
        }
        else if (resource === 'onboarding') {
            url = `${vcApiUrl}/onboarding/mint`;
        }
        else
            url = `${pfApiUrl}/${resource}`;
        
        return httpClient(url, {
            method: 'POST',
            body: JSON.stringify(params.data),
        })     
        .then(({ json }) => ({
            data: { ...json, id: json ? json.id : 1 },
        }));
    },

    update: (resource, params) => {
        let url = '';
        let payload = params.data;        
        if (resource === 'virtual_card') {
            url = `${vcApiUrl}/${resource}/basic`;
            payload = {
                id: params.data.id,
                cardLimit: params.data.currentCardProperties.cardLimit,
                validFrom: params.data.currentCardProperties.validFrom,
                validTo: params.data.currentCardProperties.validTo,
                userDefinedFields: params.data.currentCardProperties.userDefinedFields,
                translateUserDefinedFields: true
            };
        }
        else if (resource === 'cache') {
            url = `${vcApiUrl}/${resource}`;
            payload = {
                ...payload,
                cacheId: params.id,
                cardCreateRequest: params.data.linkedCreateRequestData
            };            
        }
        else if (resource === 'issuer_account') {
            url = `${vcApiUrl}/${resource}`;
        }
        else if (resource === 'user_defined_data')
            url = `${vcApiUrl}/user_defined_datamap`;
        else if (resource === 'routing') 
            url = `${vcApiUrl}/cache_route`;
        else if (resource === 'routing/currency')
            url = `${vcApiUrl}/cache_route/currency`;
        else if (resource === 'ip_access_profile')
            url = `${ipApiUrl}/access_profiles`;
        else if (resource === 'ip_issuer_management')
            url = `${ipApiUrl}/issuers`;
        else if (resource === 'ip_scheme')
            url = `${ipApiUrl}/schemes`;
        else if (resource === 'ip_card_processor_policy')
            url = `${ipApiUrl}/card_processor_policies`;
        else if (resource === 'ip_card_issuer_template')
            url = `${ipApiUrl}/card_issuer_templates`;
        else if (resource === 'ip_processor_user')
            url = `${ipApiUrl}/processor_users`;
        else if (resource === 'ip_bin_range')
            url = `${ipApiUrl}/bin_ranges`;
        else if (resource === 'ip_account')
            url = `${ipApiUrl}/accounts`;
        else if (resource === 'ip_card')
            url = `${ipApiUrl}/cards`;
        else if (resource === 'vc_access_profile') 
            url = `${vcApiUrl}/access_profile`;
        else if (resource === 'encryption_key') 
            url = `${vcApiUrl}/encryption_key`;
        else if (resource === 'issuer_credentials')
            url = `${vcApiUrl}/issuer_credential`;
        else if (resource === 'issuer_environment')
            url = `${vcApiUrl}/issuer_environment`;
        else if (resource === 'issuer_ftp')
            url = `${vcApiUrl}/issuer_ftp`;
        else if (resource === 'notification') {
                url = `${pfApiUrl}/notification`;
    
                params.data.recipients = [];
                params.data.recipientIds.forEach(recipient => {
                    params.data.recipients.push(recipient);
                });
                params.data.recipientIds = null;
            }
        else if (resource === 'organisation') {
            url = `${pfApiUrl}/${resource}/${params.id}`;
            payload = {
                id: params.data.id,
                organisationName: params.data.name,
                organisationRef: params.data.referenceCode,
                emailAddress: params.data.emailAddress
            };
        }
        else
            url = `${pfApiUrl}/${resource}/${params.id}`;

        return httpClient(url, {
            method: 'PUT',
            body: JSON.stringify(payload),
        })
        .then(({ json }) => ({
            data: { ...json, id: json ? json.id : params.id },
        }));
    },

    delete: (resource, params) => {
        let url = '';
        let payload = params.data;

        if (resource === 'virtual_card') 
            url = `${vcApiUrl}/${resource}?Id=${params.id}`;   
        else if (resource === 'cache')
            url = `${vcApiUrl}/${resource}/${params.id}`;
        else if (resource === 'ip_card')
            url = `${ipApiUrl}/cards/${params.id}`;
        else
            url = `${pfApiUrl}/${resource}/${params.id}`;

        return httpClient(url, {
            method: 'DELETE',
            body: JSON.stringify(payload),
        }).then(({ json }) => ({ data: json }));
    },

    deleteMany: async (resource, params) => {
        let url = '';
        let ids = params.ids;

        if (ids && ids.length > 0) {
            for (let i = 0; i < ids.length; i++) {
                if (resource === 'virtual_card') 
                    url = `${vcApiUrl}/${resource}?Id=${ids[i]}`;   
                else
                    continue;

                await httpClient(url, { method: 'DELETE' });
            }
            return Promise.resolve({ data: ids });
        }
        return Promise.reject();
    },

    refreshCache: (params) => {
        let url = `${vcApiUrl}/cache/refresh`;
        let payload = params;
        return httpClient(url, {
            method: 'PUT',
            body: JSON.stringify(payload),
        }).then(({ json }) => ({ data: json }));
    },

    fillCache: (params) => {
        let url = `${vcApiUrl}/cache/fill`;
        let payload = params;
        return httpClient(url, {
            method: 'PUT',
            body: JSON.stringify(payload),
        }).then(({ json }) => ({ data: json }));
    },
    
    emptyCache: (params) => {
        let url = `${vcApiUrl}/cache/empty`;
        let payload = params;
        return httpClient(url, {
            method: 'PUT',
            body: JSON.stringify(payload),
        }).then(({ json }) => ({ data: json }));
    },

    addAccountDefinedData: (params) => {
        let url = `${vcApiUrl}/issuer_account/add_defined_data`;
        let payload = params;
        return httpClient(url, {
            method: 'PUT',
            body: JSON.stringify(payload),
        }).then(({ json }) => ({ data: json }));
    },

    refreshVirtualCard: (params) => {
        let url = `${vcApiUrl}/virtual_card/refresh`;
        let payload = params;
        return httpClient(url, {
            method: 'PUT',
            body: JSON.stringify(payload),
        }).then(({ json }) => ({ data: json }));
    },
    
    getExternalUsers: () => {
        let url = `${kuggarApiUrl}/identity/external-users`
        return httpClient(url, {
            method: 'GET',
        }).then(({ json }) => ({ data: json }));
    },
    
    bulkCreateUsers: (params) => {
        let url = `${pfApiUrl}/user/bulk`;
        let payload = params;
        return httpClient(url, {
            method: 'POST',
            body: JSON.stringify(payload),
        }).then(({ json }) => ({ data: json }));
    },

    executeOpenAIJSONataFilterRule: (params) => {
        let url = `${aiApiUrl}/jsonata-filter-rule`;
        let payload = params;
        return httpClient(url, {
                method: 'POST',
                body: JSON.stringify(payload),
            })
            .then(({ json }) => ({ completed: true, data: json }))
            .catch(e => ({ completed: false, error: e.message }));
    },
};

const dataProvider = addRefreshAuthToDataProvider(myDataProvider, refreshAuth);
export default  dataProvider;