import * as React from "react";
import {
    List,
    DatagridConfigurable,
    Datagrid,
    Show,
    SimpleShowLayout,
    TextField,
    FunctionField,
    ArrayField,
    ReferenceField,
    DateInput,
    TextInput,
    SelectArrayInput,
    RadioButtonGroupInput,
    ExportButton,
    SelectColumnsButton,
    Loading,
    TopToolbar,
    useTranslate,
    useListContext,
    useRecordContext,
    useCreatePath,
    useShowController,
    CreateButton,
    WrapperField, SelectInput, BulkDeleteButton, DateField, required
} from 'react-admin';
import { Link } from 'react-router-dom';
import { useWatch } from 'react-hook-form';
import {
    DateTimeLocale,
    FormatCardNumber,
    FormatCurrencyValue,
    HasCreatePermission,
    HasDeletePermission,
    ProviderFilter,
    TablePagination
} from '../shared';
import { Environment } from '../configProvider';
import CurrencyFlag from 'react-currency-flags';
import CurrencySelect from '../components/CurrencySelect';
import ImageField from '../components/ImageField';
import OrganisationSelect from "../components/OrganisationSelect";
import CancelIcon from "@mui/icons-material/Close";
import moment from "moment/moment";

const getCardNumber = (record) => {
    const cardNum = record.cardNum === null ? record.cardNumber : record.cardNum;
    if (cardNum === null) return 'N/A';
    return `${cardNum.substring(0, 4)} ${cardNum.substring(4, 8)} ${cardNum.substring(8, 12)} ${cardNum.substring(12, 16)}`;
}

const getCustomFields = (record) => {
    let fields = [];
    for (let index = 1; index <= 20; index++) {
        const customField = record[`customField${index}`];
        if (customField !== null) {
            const fieldData = customField.split(' : ');
            if (fieldData[1] && fieldData[1] !== '' && fieldData[1] !== '-') {
                fields.push({
                    number: index,
                    name: fieldData[0],
                    value: fieldData[1],
                    linkedEntityName: fieldData[0] === 'Kuggar ID' || fieldData[0] === 'Id' || fieldData[0] === `${Environment.HostName} Reference Number` ? 'VirtualCard' : null
                });
            }
        }
    }
    return fields;
};

const CurrencyNameField = ({ source }) => {
    const record = useRecordContext();
    return record[source] == null ? `N/A` : <span><CurrencyFlag currency={record[source]} size="md" />&nbsp;{record[source]}</span>;
};

const CustomFieldDataGrid = () => {
    const translate = useTranslate();
    const record = useRecordContext();
    const createPath = useCreatePath();

    const data = { customFields: getCustomFields(record) };

    return (
        <ArrayField record={data} source="customFields">
            <Datagrid bulkActionButtons={false}>
                <TextField source="number" label={translate('kuggar.shared.custom_field_number')} />
                <TextField source="name" label={translate('kuggar.shared.name')} />
                <FunctionField label={translate('kuggar.shared.value')} render={record => (
                    record.linkedEntityName === null
                    ? <TextField source="value" label={translate('kuggar.shared.value')} />
                    : <Link to={createPath({ resource: 'virtual_card', type: 'show', id: record.value })} target="_blank">{record.value}</Link>
                )} />
            </Datagrid>
        </ArrayField>
    );
};

const ListActions = () => {
    const { total, isLoading } = useListContext();
    return (
        <TopToolbar>
            <ExportButton disabled={isLoading || total === 0} maxResults={10000} />
            <SelectColumnsButton />
        </TopToolbar>
    );
};

export const CardActivityList = () => {
    const translate = useTranslate();

    const reportFilters = [
        <TextInput label="Search" source="q" alwaysOn />,
        <DateInput label="Start Date" source="startDate" alwaysOn />,
        <DateInput label="End Date" source="endDate" alwaysOn />,
        <CurrencySelect label={translate('kuggar.shared.currency')} source="currencyCode" value="alphaCode" isFilter={true} alwaysOn />,
        <SelectArrayInput source="transactionType" choices={[
            { id: 'AUTHORISATION', name: 'Authorisation' },
            { id: 'BANK DEPOSIT', name: 'Bank Deposit' },
            { id: 'CARD CREATED', name: 'Card Created' },
            { id: 'CARD DELETED', name: 'Card Deleted' },
            { id: 'FUNDING ACCOUNT CREATED', name: 'Funding Account Created' },
            { id: 'MANUAL CREDIT', name: 'Manual Credit' },
            { id: 'MERCHANT REFUND', name: 'Merchant Refund' },
            { id: 'PURCHASE', name: 'Purchase' },
            { id: 'TRANSFER', name: 'Transfer' },
        ]} alwaysOn />,
        <OrganisationSelect source='organisationId' alwaysOn isFilter style={{width: '350px'}}/>
    ];

    return (
        <List actions={<ListActions />} filters={reportFilters} pagination={<TablePagination />} perPage={20} sort={{ field: 'TransactionDate', order: 'DESC' }} >
            <DatagridConfigurable 
                rowClick="show"
                bulkActionButtons={false}
                expand={<CustomFieldDataGrid label={translate('kuggar.shared.custom_fields')} />}
                isRowExpandable={record => getCustomFields(record).length > 0}
                omit={["organisationId","transactionAmount","merchantName"]}
            >
                <FunctionField render={src => getCardNumber(src)} label={translate('kuggar.shared.card_num')} sortBy="CardNum" />
                <ImageField source="provider" path="cards" height="50" label={translate('kuggar.shared.provider')} />
                <ReferenceField source="organisationId" label={translate('kuggar.organisation.organisation')} reference="organisation" link={false}>
					<FunctionField render={record => `${record.name} (${record.referenceCode})`}/>
				</ReferenceField>
                <TextField source="fundingAccountName" label={translate('kuggar.shared.funding_account_name')} sortBy="FundingAccountName" />
                <TextField source="merchantName" label={translate('kuggar.shared.merchant_name')} sortBy="MerchantName" />
                <TextField source="transactionDate" label={translate('kuggar.shared.transaction_date')} sortBy="TransactionDate" sortByOrder="DESC" />
                <CurrencyNameField source="transactionCurrency" label={translate('kuggar.shared.transaction_currency')} sortBy="TransactionCurrency" />
                <TextField source="transactionType" label={translate('kuggar.shared.transaction_type')} sortBy="TransactionType" />
                <FunctionField source="transactionAmount" render={record => FormatCurrencyValue(record.transactionCurrency, record.transactionAmount)} label={translate('kuggar.shared.transaction_amount')} sortable={false}/>
                <FunctionField source="balanceBefore" render={record => FormatCurrencyValue(record.transactionCurrency, record.balanceBefore)} label={translate('kuggar.shared.balance_before')} sortable={false}/>
                <FunctionField source="balanceAdjustment" render={record => FormatCurrencyValue(record.transactionCurrency, record.balanceAdjustment)} label={translate('kuggar.shared.balance_adjustment')} sortable={false}/>
                <FunctionField source="balanceAfter" render={record => FormatCurrencyValue(record.transactionCurrency, record.balanceAfter)} label={translate('kuggar.shared.balance_after')} sortable={false}/>
            </DatagridConfigurable>
        </List>
    );
};

export const CardActivityShow = () => {
    const translate = useTranslate();
    const createPath = useCreatePath();
    const { isLoading, record } = useShowController();
    if (isLoading) return <Loading />;

    console.log(record);

    const Title = () => {
        const rec = useRecordContext();
        return <span>Card Activity - ID {rec ? rec.id : ''}</span>;
    };

    const Actions = () => {
		return (
			<TopToolbar>
			
			</TopToolbar>
		);
    };

    return (
        <Show title={<Title />} actions={<Actions />}>
            <SimpleShowLayout>
                <FunctionField render={record => {
                    const cardNumber = getCardNumber(record);

                    if (record.virtualCardId && record.virtualCardId !== '')
                        return <Link to={createPath({ resource: 'virtual_card', type: 'show', id: record.virtualCardId })}>{cardNumber}</Link>;

                    const virtualCardFields = getCustomFields(record).filter(field => field.linkedEntityName === 'VirtualCard');
                    if (virtualCardFields.length > 0)
                        return <Link to={createPath({ resource: 'virtual_card', type: 'show', id: virtualCardFields[0].value })}>{cardNumber}</Link>;

                    return cardNumber;
                }} label={translate('kuggar.shared.card_num')} />
                <ImageField source="provider" path="cards" height="50" label={translate('kuggar.shared.provider')} />
				<ReferenceField source="organisationId" label={translate('kuggar.organisation.organisation')} reference="organisation" link={false}>
					<FunctionField render={record => `${record.name} (${record.referenceCode})`}/>
				</ReferenceField>
                <TextField source="filename" label={translate('kuggar.shared.file_name')} />
                <TextField source="fundingAccountName" label={translate('kuggar.shared.funding_account_name')} sortBy="FundingAccountName" />
                <TextField source="transactionDate" label={translate('kuggar.shared.transaction_date')} />
                <CurrencyNameField source="transactionCurrency" label={translate('kuggar.shared.transaction_currency')} />
                <TextField source="transactionType" label={translate('kuggar.shared.transaction_type')} />
                {record.provider !== 'Mint' && <FunctionField source="transactionAmount" render={record => FormatCurrencyValue(record.transactionCurrency, record.transactionAmount)} label={translate('kuggar.shared.transaction_amount')} sortable={false} />}
                {record.provider === 'Mint' && <FunctionField source="balanceBefore" render={record => FormatCurrencyValue(record.transactionCurrency, record.balanceBefore)} label={translate('kuggar.shared.balance_before')} sortable={false} />}
                {record.provider === 'Mint' && <FunctionField source="balanceAdjustment" render={record => FormatCurrencyValue(record.transactionCurrency, record.balanceAdjustment)} label={translate('kuggar.shared.balance_adjustment')} sortable={false } />}
                {record.provider === 'Mint' && <FunctionField source="balanceAfter" render={record => FormatCurrencyValue(record.transactionCurrency, record.balanceAfter)} label={translate('kuggar.shared.balance_after')} sortable={false} />}
                <CustomFieldDataGrid label={translate('kuggar.shared.custom_fields')} />
            </SimpleShowLayout>
        </Show>
    );
};

export const FundingAccountActivityList = () => {
    const translate = useTranslate();

    const reportFilters = [
        <TextInput label="Search" source="q" alwaysOn />,
        <DateInput label="Start Date" source="startDate" alwaysOn />,
        <DateInput label="End Date" source="endDate" alwaysOn />,
        <CurrencySelect label={translate('kuggar.shared.currency')} source="currencyCode" value="alphaCode" isFilter={true} alwaysOn />,
        <SelectArrayInput source="transactionType" choices={[
            { id: 'AUTHORISATION', name: 'Authorisation' },
            { id: 'BANK DEPOSIT', name: 'Bank Deposit' },
            { id: 'CARD CREATED', name: 'Card Created' },
            { id: 'CARD DELETED', name: 'Card Deleted' },
            { id: 'FUNDING ACCOUNT CREATED', name: 'Funding Account Created' },
            { id: 'MANUAL CREDIT', name: 'Manual Credit' },
            { id: 'PURCHASE', name: 'Purchase' },
            { id: 'TRANSFER', name: 'Transfer' },
        ]} alwaysOn />,
        <OrganisationSelect source='organisationId' alwaysOn isFilter style={{width: '350px'}}/>
    ];

    return (
        <List filters={reportFilters} pagination={<TablePagination />} perPage={20} sort={{ field: 'TransactionDate', order: 'DESC' }} >
            <Datagrid 
                rowClick="show"
                bulkActionButtons={false}
            >
                <TextField source="participantDetails" label={translate('kuggar.shared.funding_account_name')} sortBy="ParticipantDetails" />
                <TextField source="transactionDate" label={translate('kuggar.shared.transaction_date')} sortBy="TransactionDate" sortByOrder="DESC" />
                <CurrencyNameField source="transactionCurrency" label={translate('kuggar.shared.transaction_currency')} sortBy="TransactionCurrency" />
                <TextField source="transactionType" label={translate('kuggar.shared.transaction_type')} sortBy="TransactionType" />
                <FunctionField render={record => FormatCurrencyValue(record.transactionCurrency, record.balanceBefore)} label={translate('kuggar.shared.balance_before')} sortable={false}/>
                <FunctionField render={record => FormatCurrencyValue(record.transactionCurrency, record.balanceAdjustment)} label={translate('kuggar.shared.balance_adjustment')} sortable={false}/>
                <FunctionField render={record => FormatCurrencyValue(record.transactionCurrency, record.balanceAfter)} label={translate('kuggar.shared.balance_after')} sortable={false}/>
            </Datagrid>
        </List>
    );
};

export const FundingAccountActivityShow = () => {
    const translate = useTranslate();

    const Title = () => {
        const record = useRecordContext();
        return <span>Funding Account Activity - ID {record ? record.id : ''}</span>;
    };

    const Actions = () => {
		return (
			<TopToolbar>
			
			</TopToolbar>
		);
    };

    return (
        <Show title={<Title />} actions={<Actions />}>
            <SimpleShowLayout>
                <TextField source="participantDetails" label={translate('kuggar.shared.funding_account_name')} />
				<ReferenceField source="organisationId" label={translate('kuggar.organisation.organisation')} reference="organisation" link={false}>
					<FunctionField render={record => `${record.name} (${record.referenceCode})`}/>
				</ReferenceField>
                <TextField source="filename" label={translate('kuggar.shared.file_name')} />
                <TextField source="transactionDate" label={translate('kuggar.shared.transaction_date')} />
                <CurrencyNameField source="transactionCurrency" label={translate('kuggar.shared.transaction_currency')} />
                <TextField source="transactionType" label={translate('kuggar.shared.transaction_type')} />
                <FunctionField render={record => FormatCurrencyValue(record.transactionCurrency, record.balanceBefore)} label={translate('kuggar.shared.balance_before')} sortable={false}/>
                <FunctionField render={record => FormatCurrencyValue(record.transactionCurrency, record.balanceAdjustment)} label={translate('kuggar.shared.balance_adjustment')} sortable={false}/>
                <FunctionField render={record => FormatCurrencyValue(record.transactionCurrency, record.balanceAfter)} label={translate('kuggar.shared.balance_after')} sortable={false}/>
            </SimpleShowLayout>
        </Show>
    );
};

export const NonZeroCardBalanceList = () => {
    const translate = useTranslate();

    const AsAtDateInput = props => {
        const active = useWatch({ name: 'active' });
        return <DateInput label={props.label} source={props.source} disabled={active === 'true'} />;
    };

    const reportFilters = [
        <TextInput label="Search" source="q" alwaysOn />,
        <CurrencySelect label={translate('kuggar.shared.currency')} source="currencyCode" value="alphaCode" isFilter={true} alwaysOn />,
        <RadioButtonGroupInput label={false} source="active" choices={[
            { id: true, name: 'Latest' },
            { id: false, name: 'Historical' }
        ]} alwaysOn />,
        <AsAtDateInput label="As At Date" source="startDate" alwaysOn />,
        <OrganisationSelect source='organisationId' alwaysOn isFilter style={{width: '350px'}}/>
    ];

    return (
        <List filters={reportFilters} filterDefaultValues={{ active: true }} pagination={<TablePagination />} perPage={20} sort={{ field: 'AsAtDate', order: 'DESC' }} >
            <Datagrid 
                rowClick="show"
                bulkActionButtons={false}
                expand={<CustomFieldDataGrid label={translate('kuggar.shared.custom_fields')} />}
                isRowExpandable={record => getCustomFields(record).length > 0}
            >
                <FunctionField render={src => getCardNumber(src)} label={translate('kuggar.shared.card_num')} sortBy="CardNumber" />
				<TextField source="client" label={translate('kuggar.shared.client')} sortBy="Client" />
                <TextField source="asAtDate" label={translate('kuggar.shared.as_at_date')} sortBy="AsAtDate" sortByOrder="DESC" />
                <CurrencyNameField source="cardCurrency" label={translate('kuggar.shared.card_currency')} sortBy="CardCurrency"  />
                <FunctionField render={record => FormatCurrencyValue(record.cardCurrency, record.loadedAmount)} label={translate('kuggar.shared.loaded_amount')} sortable={false}/>
                <FunctionField render={record => FormatCurrencyValue(record.cardCurrency, record.availableBalance)} label={translate('kuggar.shared.available_balance')} sortable={false}/>
            </Datagrid>
        </List>
    );
};

export const NonZeroCardBalanceShow = () => {
    const translate = useTranslate();
    const createPath = useCreatePath();

    const Title = () => {
        const record = useRecordContext();
        return <span>Non-Zero Card Balance - ID {record ? record.id : ''}</span>;
    };

    const Actions = () => {
		return (
			<TopToolbar>
			
			</TopToolbar>
		);
    };

    return (
        <Show title={<Title />} actions={<Actions />}>
            <SimpleShowLayout>
                <FunctionField render={record => {
                    const cardNumber = getCardNumber(record);
                    const virtualCardFields = getCustomFields(record).filter(field => field.linkedEntityName === 'VirtualCard');
                    if (virtualCardFields.length > 0)
                        return <Link to={createPath({ resource: 'virtual_card', type: 'show', id: virtualCardFields[0].value })} target="_blank">{cardNumber}</Link>;

                    return cardNumber;
                }} label={translate('kuggar.shared.card_num')} />
				<TextField source="client" label={translate('kuggar.shared.client')} />
                <TextField source="filename" label={translate('kuggar.shared.file_name')} />
                <TextField source="asAtDate" label={translate('kuggar.shared.as_at_date')} />
                <CurrencyNameField source="cardCurrency" label={translate('kuggar.shared.card_currency')} />
                <FunctionField render={record => FormatCurrencyValue(record.cardCurrency, record.loadedAmount)} label={translate('kuggar.shared.loaded_amount')} sortable={false}/>
                <FunctionField render={record => FormatCurrencyValue(record.cardCurrency, record.availableBalance)} label={translate('kuggar.shared.available_balance')} sortable={false}/>
                <CustomFieldDataGrid label={translate('kuggar.shared.custom_fields')} />
            </SimpleShowLayout>
        </Show>
    );
};

export const VirtualCardExpiryList = () => {
    const translate = useTranslate();

    const Actions = () => {
        return (
            <TopToolbar>

            </TopToolbar>
        );
    };


    const StateField = ({label}) => {
        const record = useRecordContext();
        const { state } = record;

        let color = 'black';

        if (state && state.startsWith('Failed'))
            color = 'red'

        return <WrapperField label={label}><span style={{color}}>{state}</span></WrapperField>
    };

    const virtualCardFilter = [
        <TextInput label={`${translate('kuggar.shared.kuggar_id', Environment)} / ${translate('kuggar.virtual_cards.provider_id')}`} source="q" alwaysOn />,
        <ProviderFilter alwaysOn />,
        <SelectInput label={translate('kuggar.shared.state')} source="state" choices={[
            { id: 'Updated', name: 'Updated' },
            { id: 'Updating', name: 'Updating' },
            { id: 'Cancelled', name: 'Cancelled' },
            { id: 'Cancelling', name: 'Cancelling' },
            { id: 'FailedToUpdate', name: 'Failed To Update' },
            { id: 'FailedToCancel', name: 'Failed To Cancel' },
            { id: 'Expired', name: 'Expired' }
        ]} alwaysOn />,
        <SelectInput label={translate('kuggar.shared.time_till_expiry')} source="daysToExpiry" choices={[
            { id: 1, name: '1 Day' },
            { id: 3, name: '3 Days' },
            { id: 5, name: '5 Days' },
            { id: 7, name: '1 Week' },
        ]} alwaysOn validate={required()}/>,
        <CurrencySelect label={translate('kuggar.shared.currency')} source="currencyCode" value="alphaCode" isFilter={true} alwaysOn />
    ];

    return (
        <List actions={<Actions />} filters={virtualCardFilter} filterDefaultValues={{ daysToExpiry: 7 }} pagination={<TablePagination />} perPage={10} sort={{ field: 'CurrentCardProperties.DateTimeUtc', order: 'DESC' }} hasCreate={HasCreatePermission('VirtualCards', '/virtual_card')}>
            <DatagridConfigurable
                rowClick={false}
                bulkActionButtons={false}
                omit={[
                    "record.organisation.id",
                    "issuerAccountId"
                ]}>
                <TextField source="id" label={translate('kuggar.shared.kuggar_id', Environment)} />
                <FunctionField source="record.organisation.id" label={translate('kuggar.organisation.organisation')} render={record => record.organisation ? `${record.organisation.name} (${record.organisation.referenceCode})` : '-'} />
                <ReferenceField source="issuerAccountId" label={translate('kuggar.shared.issuer_account')} reference="issuer_account" link="show">
                    <TextField source="description" />
                </ReferenceField>
                <ImageField source="provider" path="cards" height="50" label={translate('kuggar.virtual_cards.provider')} />
                <TextField source="currentCardProperties.providerId" label={translate('kuggar.virtual_cards.provider_id')} />
                <StateField label={translate('kuggar.virtual_cards.state')} />
                <FunctionField label={translate('kuggar.virtual_cards.card_number')} sortable={false} render={record => FormatCardNumber(record.currentCardProperties.cardNumber)} />
                <FunctionField label={translate('kuggar.virtual_cards.valid_from')} sortable={false} render={record => moment(record.currentCardProperties.validFrom).format('YYYY-MM-DD')} />
                <FunctionField label={translate('kuggar.virtual_cards.valid_to')} sortable={false} render={record => moment(record.currentCardProperties.validTo).format('YYYY-MM-DD')} />
                <FunctionField label={translate('kuggar.virtual_cards.card_limit')} sortable={false} render={record => {
                    let currencyCode = record.currentCardProperties.currencyCodes[0];
                    let cardLimit = record.currentCardProperties.cardLimit;
                    return <span style={{verticalAlign:'top'}}><CurrencyFlag currency={currencyCode} size="md" style={{verticalAlign:'top'}} />&nbsp;{FormatCurrencyValue(currencyCode, cardLimit)}</span>;
                }} />
                <DateField source="currentCardProperties.dateTimeUtc" label={translate('kuggar.virtual_cards.last_refreshed')} showTime locales={DateTimeLocale} />
            </DatagridConfigurable>
        </List>
    );
};